@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/variable';

body {
    font-family: var(--font-inter), sans-serif;
}
#root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rootPage {
    height: 100%;
}
