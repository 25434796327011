.homePageContainer {
    .bodyLottery {
        width: 100%;
        max-width: 1170px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .resultLotteryList {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .lotteryItem {
            padding: 12px 0;
            flex: 0 0 calc(50% - 16px);
            display: flex;
            flex-direction: column;
            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 16px;
            }
        }

        .lotteryItemTitle {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 4px;
        }
        .noDataLottery {
            width: 100%;
            padding: 20% 0;
            text-align: center;
            img {
                margin-bottom: 4px;
                width: 100px;
                filter: grayscale(0.5);
            }
        }
        .loadingList {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 24px;
            .loadingItem {
                flex: 0 0 calc(50% - 16px);
                margin: 0 8px;
                margin-bottom: 16px;
                .loadingTitle {
                    margin-bottom: 4px;
                }
                .loadingTable {
                    height: 347px;
                    display: flex;
                    .tableResult {
                        flex: 0 0 50%;
                    }
                    .issueList,
                    .nextIssue {
                        flex: 0 0 calc(25% - 2px);
                        margin-left: 2px;
                    }
                }
            }
        }
    }
    .descriptionSection {
        margin-top: 12px;
        background: var(--gray30-color);
        padding: 24px;
        text-align: center;
        .descriptionInner {
            width: 100%;
            max-width: 1140px;
            margin: 0 auto;
        }
        .descTitle {
            font-size: 24px;
            font-weight: 500;
            color: var(--secondary-color);
        }
        .regionAvailabel {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 32px 0;
            .countryTitle {
                text-transform: uppercase;
            }
            .regionList {
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            .regionItem {
                display: flex;
                align-items: center;
                margin: 0 12px;
                img {
                    width: 36px;
                }
                p {
                    text-transform: capitalize;
                    margin-left: 2px;
                    font-size: 14px;
                }
            }
        }
        .descContent {
            font-size: 13px;
            color: #4e535a;
            width: 100%;
            max-width: 1140px;
            margin: 0 auto;
        }
    }
}
@media (max-width: 991.98px) {
    .homePageContainer {
        .resultLotteryList {
            .lotteryItem {
                flex: 0 0 100%;
            }
        }

        .bodyLottery {
            padding: 0 12px;
        }
        .descriptionSection {
            padding: 12px 8px;
            .countryTitle {
                display: none;
                margin-right: 4px;
            }
            .regionAvailabel {
                align-items: flex-start;
                margin: 24px 0;
                .regionList {
                    justify-content: center;
                    margin-left: 8px;
                    .regionItem {
                        flex: 0 0 25%;
                        margin: 0;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
    .homePageContainer {
        .bannerSlide {
            .img-slider {
                height: 180px;
            }
        }
        .resultLotteryList {
            .lotteryItem {
                width: 100%;
                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0;
                }
                &:nth-child(1) {
                    margin-top: 12px;
                }
            }
            .loadingList {
                .loadingItem {
                    flex: 0 0 100%;
                    margin: 0;
                    margin-bottom: 12px;
                    .loadingTable {
                        flex-wrap: wrap;
                        .tableResult {
                            flex: 0 0 65%;
                            height: calc(100% - 100px);
                        }
                        .issueList {
                            flex: 0 0 calc(35% - 2px);
                        }
                        .nextIssue {
                            margin-left: 0;
                            margin-top: 2px;
                            flex: 0 0 100%;
                            height: 100px;
                        }
                    }
                }
            }
        }
        .descriptionSection {
            .descTitle {
                font-size: 18px;
            }
            .regionAvailabel {
                align-items: flex-start;
                margin: 16px 0;
                .regionList {
                    .regionItem {
                        flex: 0 0 33.333%;
                    }
                }
            }
        }
    }
}
