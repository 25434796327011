.defaultLayout {
    height: 100%;
    display: flex;
    flex-direction: column;

    .headerContainer {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 2;
        height: 65px;
    }

    //memu mobile
    .menuSidebarMobile {
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;

        margin-top: 65px;
        z-index: 2;
        display: none;
        .menuSidebarContainer {
            width: 100%;
            height: 100%;
            position: relative;
            .overlayMenu {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.6);
                z-index: 1;
                display: none;
            }
            &.open {
                pointer-events: initial;
                .overlayMenu {
                    display: block;
                }
                .menuSidebarList {
                    transform: translateX(0);
                }
            }
        }

        .menuSidebarList {
            position: relative;
            z-index: 2;
            width: fit-content;
            height: 100%;
            background: var(--white-color);

            padding: 12px;
            transform: translateX(-100%);
            transition: 0.25s;

            .menuItem {
                padding: 8px;
            }
            .countryName {
                cursor: pointer;
                text-transform: uppercase;
            }
            .active {
                color: red;
            }
            a {
                font-weight: 500;
            }
        }
    }
    .defaultContent {
        margin-top: 65px;
        flex: 1;
    }
    .popupNoticeContainer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        .popupNoticeInner {
            width: 100%;
            height: 100%;
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;
            .popupOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.6);
            }
            .popupContent {
                position: relative;
                z-index: 1;
                background: var(--white-color);
                padding: 16px;
                border-radius: 12px;
                text-align: center;
                .btnClose {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    color: var(--secondary-color);
                    cursor: pointer;
                }
                img {
                    margin-top: 24px;
                    width: 60px;
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .defaultLayout {
        .menuSidebarMobile {
            display: block;
            pointer-events: none;
        }
    }
}

@media (max-width: 575.98px) {
    .defaultLayout {
        .headerContainer {
            height: 60px;
        }
        .defaultContent,
        .menuSidebarMobile {
            margin-top: 60px;
        }
    }
}
