//font inter
@font-face {
    src: url(../assets/fonts/Inter-Regular.ttf);
    font-family: Inter;
    font-weight: 400;
}

@font-face {
    src: url(../assets/fonts/Inter-Medium.ttf);
    font-family: Inter;
    font-weight: 500;
}

@font-face {
    src: url(../assets/fonts/Inter-SemiBold.ttf);
    font-family: Inter;
    font-weight: 600;
}

@font-face {
    src: url(../assets/fonts/Inter-Bold.ttf);
    font-family: Inter;
    font-weight: 700;
}
