.footer {
    background: var(--drak-color);
    padding: 32px 0;
    color: #7f7f7f;
    .footerContent {
        width: 100%;
        max-width: 1170px;
        padding: 0 15px;
        margin: 0 auto;
        display: flex;
    }
    .footerLeft {
        flex: 1;
        .logoFooter {
            width: 100px;
        }
        .appDownload {
            margin: 24px 0;
            button {
                margin-right: 32px;
                border: 1px solid #535760;
                padding: 4px;
                width: 40px;
                height: 40px;
                border-radius: 6px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .pagcorLicense {
            display: flex;
            align-items: center;
            img {
                width: 60px;
                margin-right: 6px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .footerCenter {
        flex: 0 0 30%;
        p {
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .partnerList {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            div {
                flex: 0 0 calc(50% - 8px);
                height: 32px;
                margin-bottom: 12px;
                img {
                    height: 100%;
                    width: auto;
                    object-fit: cover;
                }
            }
        }
    }
    .footerRight {
        flex: 0 0 30%;
        .pageList {
            margin: 0 auto;
            width: fit-content;
        }
        .pageItem {
            font-size: 14px;
            margin-bottom: 12px;
            text-transform: uppercase;
        }
    }
}
@media (max-width: 991.98px) {
    .footer {
        .footerCenter {
            .partnerList {
                div {
                    img {
                        width: 100px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    .footer {
        padding: 16px 0;
        .footerContent {
            flex-direction: column;
            .footerLeft {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;

                .appDownload {
                    margin: 8px 0;
                    button {
                        margin-right: 12px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .pagcorLicense {
                    flex-direction: column;
                    p {
                        margin-top: 4px;
                    }
                }
            }
            .footerCenter {
                flex-direction: column;
                border-bottom: 1px solid var(--gray-drak);
                > p {
                    margin-bottom: 8px;
                }
                .partnerList {
                    div {
                        margin-bottom: 8px;
                        flex: 0 0 33.333%;
                        text-align: center;
                    }
                    img {
                        width: 60px;
                    }
                }
            }
            .footerRight {
                margin-top: 21px;
                .pageList {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    .pageItem {
                        flex: 0 0 33.333%;
                        text-align: center;
                        &:nth-child(1),
                        &:nth-child(2) {
                            flex: 0 0 50%;
                        }
                    }
                }
            }
        }
    }
}
