.headerWrapper {
    background: var(--primary-color);
    height: 100%;
    display: flex;
    align-items: center;
    .headerContent {
        width: 100%;
        max-width: 1170px;
        padding: 8px 15px;
        display: flex;
        align-items: center;
        margin: 0 auto;
    }
    .btn-menu-mobile {
        display: none;
        margin-right: 8px;
    }
    .logo {
        width: 100px;
    }
    .menuList {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        .menuItem {
            padding: 4px 8px;
        }
        .countryName {
            cursor: pointer;
            text-transform: uppercase;
        }
        .active {
            color: red;
        }
        a {
            font-weight: 500;
        }
    }
    .language {
        position: relative;
        width: 130px;
        margin-left: auto;
        .btnLanguage {
            padding: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: auto;
            p {
                margin-left: 8px;
                margin-right: 4px;
                font-size: 14px;
            }
            img {
                width: 30px;
            }
        }
        .optionLanguage {
            position: absolute;
            top: 100%;
            width: 180px;
            right: 0;
            transform: translateY(40px);

            opacity: 0;
            pointer-events: none;
            transition: 0.25s;
            z-index: 1;

            &.open {
                transform: translateY(20px);
                opacity: 1;
                pointer-events: initial;
            }
        }

        .languageList {
            background: var(--white-color);
            padding: 6px 12px;
            padding-left: 2px;
            border-radius: 12px;
            -webkit-box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.4);
            -moz-box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.4);
            box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.4);
        }
        .languageItem {
            display: flex;
            align-items: center;
            padding: 8px;
            border-radius: 6px;
            cursor: pointer;
            svg {
                opacity: 0;
            }

            img {
                width: 30px;
                margin-right: 4px;
            }
            &:hover {
                background: var(--gray30-color);
            }
            .active {
                opacity: 1;
            }
        }
    }
}

@media (max-width: 991.98px) {
    .headerWrapper {
        .headerContent {
            .btn-menu-mobile {
                display: block;
            }
            .menuList {
                display: none;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .headerWrapper {
        .headerContent {
            .logo {
                width: 80px;
            }
        }
    }
}
