* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    text-decoration: none;
    color: var(--black-color);
    &:active,
    &:focus {
        outline: none;
    }
}
img {
    width: 100%;
}

table {
    border-collapse: collapse;
}
button {
    background: none;
    border: none;
}

input {
    &:focus,
    &:active {
        outline: none;
    }
}

li {
    list-style-type: none;
}
