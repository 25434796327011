.tableResultLottery {
    flex: 1;
    width: 100%;
    .numberItem {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    td {
        border: 1px solid var(--gray-color);
        text-align: center;
        padding: 4px 2px;
    }
    tr {
        td {
            &:first-child {
                width: 80px;
                font-size: 14px;
                font-weight: 500;
                // text-transform: capitalize;
            }
        }
    }
    tr:nth-child(even) {
        background-color: var(--gray30-color);
    }
    //icon loading
    .prize0 {
        .iconLoading {
            width: 26px;
            height: 26px;
        }
    }

    //font size
    .prize2,
    .prize3,
    .prize4,
    .prize5,
    .prize6,
    .prize7 {
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;
    }

    .prize1,
    .prize2 {
        font-size: 18px;
        .iconLoading {
            width: 21px;
            height: 21px;
        }
    }
    .prize4,
    .prize3,
    .prize5 {
        font-size: 14px;
        .iconLoading {
            width: 17px;
            height: 17px;
        }
    }
    .prize6 {
        .iconLoading {
            width: 20px;
            height: 20px;
        }
    }
    .prize7,
    .prize8 {
        .iconLoading {
            width: 24px;
            height: 24px;
        }
    }
    &.northern {
        .prize0 {
            font-size: 22px;
            font-weight: 700;
            color: var(--secondary-color);
        }
        .prize1 {
            font-size: 18px;
            font-weight: 700;
        }
        .prize2 {
            .numberItem {
                flex: 1;
            }
        }
        .prize3,
        .prize5,
        .prize6 {
            margin: -2px 0;
            .numberItem {
                flex: 0 0 33.3333%;
                padding: 2px 0;
            }
        }
        .prize4 {
            margin: -2px 0;
            .numberItem {
                flex: 0 0 50%;
                padding: 2px 0;
            }
        }
        .prize7 {
            .numberItem {
                flex: 0 0 25%;
                color: var(--secondary-color);
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
    &.southern {
        .prize0 {
            font-size: 22px;
            font-weight: 700;
            color: var(--secondary-color);
        }
        .prize1 {
            font-size: 20px;
            font-weight: 700;
        }
        .prize2 {
            justify-content: center;
            font-size: 18px;
            font-weight: 700;
        }
        .prize3 {
            .numberItem {
                flex: 1;
            }
        }
        .prize4 {
            justify-content: center;
            margin: -2px 0;

            .numberItem {
                padding: 2px 0;
                flex: 0 0 25%;
            }
        }
        .prize6 {
            .numberItem {
                flex: 0 0 33.3333%;
            }
        }
        .prize5,
        .prize7,
        .prize8 {
            .numberItem {
                flex: 1;
            }
        }
        .prize8 {
            .numberItem {
                font-size: 22px;
                font-weight: 700;
                color: var(--secondary-color);
            }
        }
    }
    .noResult {
        padding: 24px 0;
    }
}
