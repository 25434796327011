.detailPage {
    width: 100%;
    max-width: 1170px;
    padding: 12px 15px;
    margin: 0 auto;
    height: 100%;

    display: flex;
    flex-direction: column;
    .detailHeader {
        text-align: center;
        padding: 16px 0;
        .detaiTitle {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 22px;
        }
    }
    .detailBody {
        flex: 1;
    }
}
