html {
    --primary-color: #ffc104;
    --secondary-color: #d60500;
    --white-color: #fff;
    --black-color: #000;
    --drak-color: #24262b;
    --gray-drak: #818995;

    //gray
    --gray-color: #c1cbdc;
    --gray30-color: #eceff5;
    --gray60-color: #d1d1d1;
    --gradient-primary: linear-gradient(180deg, #ef4eae 34.69%, #ef53a3 56.04%, #ef7e4e 100%);
    --font-inter: 'Inter';
}
