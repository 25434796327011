.lotteryContainer {
    display: flex;
    flex: 1;
    p,
    span {
        text-transform: capitalize;
    }
    .resultTitle {
        background: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 8px 12px;
        height: 48px;
        border-right: 1px solid var(--gray-color);
        font-size: 14px;
        .resultOption {
            display: flex;
            align-items: center;
            select {
                text-transform: capitalize;
                margin-left: 4px;
                padding: 2px 4px;
                border-radius: 6px;
            }
        }
    }
    .resultTable {
        flex: 1;
        display: flex;
        flex-direction: column;
        .resultTitle {
            .viewOptionItem {
                display: flex;
                align-items: center;
                margin-right: 12px;
            }

            label {
                font-size: 14px;
                padding: 4px;
                text-transform: capitalize;
            }
        }
    }

    .sectionResultList {
        display: flex;
        flex-direction: column;
        .resultTitle {
            border-left: 1px solid var(--gray-color);
        }
        .issueList {
            display: flex;
            flex-direction: column;
            flex: 1;
            .issueItem {
                flex: 1;
                text-align: center;
                display: flex;
                align-items: center;
                font-size: 13px;
                justify-content: center;

                padding: 0 6px;
                border: 1px solid var(--gray-color);
                border-bottom: none;
                cursor: pointer;

                &:last-child {
                    border-bottom: 1px solid var(--gray-color);
                }
                // &:nth-child(odd) {
                //     background: var(--gray30-color);
                // }
                &:hover {
                    background: var(--primary-color);
                }
                &.active {
                    background: var(--gray30-color);
                    color: var(--secondary-color);
                    font-weight: 500;
                }
            }
        }
    }
    .nextIssueWrapper {
        display: flex;
        flex-direction: column;
        .resultTitle {
            border-left: 1px solid var(--gray-color);
        }
        .nextIssueContent {
            flex: 1;
            background: var(--gray30-color);
            border: 1px solid var(--gray-color);
            padding: 0 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .issueId,
            .issueDate,
            .issueTimeline,
            .issueTimeDesc {
                text-align: center;
                margin-top: 8px;
                font-size: 14px;
                p {
                    font-size: 14px;
                    color: var(--gray-drak);
                }
                span {
                    font-weight: 600;
                    font-size: 15px;
                }
            }
            .issueTime {
                margin-bottom: 8px;
            }

            .issueTimeCount {
                font-size: 14px;
                font-weight: 500;
                padding: 4px 12px;
                margin: 0 auto;

                width: 104px;
                text-align: center;
            }
            .btnDetail {
                font-size: 14px;
                background: var(--secondary-color);
                color: var(--white-color);
                padding: 6px 24px;
                margin: 0 auto;

                width: fit-content;
                border-radius: 8px;
                text-transform: capitalize;
                cursor: pointer;
            }
            .titleNextIssueMobile {
                display: none;
            }
        }
    }
    &.detail {
        height: 100%;
        padding-bottom: 16px;
        .sectionResultList {
            flex: 0 0 20%;
            max-width: 20%;
            .issueList {
                .issueItem {
                    padding: 12px 0;
                }
            }
        }
        .nextIssueWrapper {
            flex: 0 0 30%;
            max-width: 30%;
        }
        .resultTable {
            .numberItem {
                padding: 6px 0;
            }
            tr {
                td {
                    &:first-child {
                        padding: 0 12px;
                    }
                }
            }
            .prize0 {
                font-size: 24px;
            }
            .prize1,
            .prize2 {
                font-size: 22px;
            }
            .prize3,
            .prize4,
            .prize5 {
                font-size: 18px;
            }
            .prize6 {
                font-size: 20px;
            }
            .prize7,
            .prize8 {
                .numberItem {
                    font-size: 22px;
                }
            }
        }
    }
}

@keyframes flicker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 575.98px) {
    .lotteryContainer {
        flex-wrap: wrap;
        .resultTitle {
            justify-content: center;
            // border-left: none !important;
            // border-right: none !important;
            // height: auto !important;
            // padding: 10px 6px !important;
        }
        .sectionResultList {
            .resultTitle {
                border-left: none;
                border-right: none;
                height: 43px;
            }
            .issueList {
                .issueItem {
                    border-right: none;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        .resultTable {
            flex: 1;
            .resultTitle {
                height: 43px;
                padding: 8px;
                label {
                    font-size: 12px;
                }
            }
        }
        .sectionResultList {
            flex: 0 0 25%;
            border: 1px solid var(--gray-color);
            .issueList {
                .issueItem {
                    border-left: none;
                    text-align: center;
                }
            }
        }

        .nextIssueWrapper {
            .resultTitle {
                display: none;
            }
            .nextIssueContent {
                flex-direction: row;
                flex-wrap: wrap;
                background: none;
                padding: 6px 0;
                > div {
                    flex: 0 0 50%;
                    margin-top: 0 !important;
                    margin-bottom: 4px;
                }
                .issueTime {
                    margin-bottom: 0;
                    .issueTimeDesc {
                        margin-top: 0;
                    }
                }
                .titleNextIssueMobile {
                    display: block;
                    flex: 0 0 100%;
                    text-align: center;
                    margin-bottom: 4px;
                }
            }
        }
    }
}
