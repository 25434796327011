.tabChanelContainer {
    background: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border-radius: 12px;
    margin-top: 12px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    .countryImage {
        img {
            width: 40px;
        }
    }
    .listChanel {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        margin-left: 40px;
    }
    .chanelItem {
        padding: 6px 12px;
        margin: 0 12px;
        border-radius: 8px;
        cursor: pointer;
        text-transform: uppercase;
    }
    .active {
        background: var(--secondary-color);
        color: var(--white-color);
    }
}

@media (max-width: 767.98px) {
    .tabChanelContainer {
        flex-direction: column;
        padding-bottom: 0;
        background: var(--white-color);
        border: 1px solid var(--primary-color);
        padding: 0;
        overflow: hidden;

        .countryImage {
            width: 100%;
            text-align: center;
            background: var(--primary-color);
            padding: 4px 0;
        }
        .listChanel {
            margin-left: 0;
            justify-content: center;
            background: var(--gray30-color);
            padding: 8px;
            padding-bottom: 0;
            width: 100%;
            .chanelItem {
                flex: 0 0 20%;
                margin: 0;
                font-size: 14px;
                text-align: center;
                margin-bottom: 8px;
            }
        }
    }
}
@media (max-width: 767.98px) {
    .tabChanelContainer {
        .listChanel {
            .chanelItem {
                flex: 0 0 33.333%;
                &:nth-child(1),
                &:nth-child(2) {
                    flex: 0 0 40%;
                }
            }
        }
    }
}
